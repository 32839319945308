.navbar {
    background-color: white;
    border-bottom: 1px solid #e5e7eb;
}

.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filterIcon{
    width: 20px;
    height: 20px;
    padding: 0 2px ;
}

.menu-icon {
    width: 1.25rem;
    height: 1.25rem;
}

.navbar-menu {
    display: none;
    width: 100%;
}

.navbar-menu.open {
    display: block;
}

.navbar-links {
    list-style: none;
    padding: 0;
    margin: 1rem 0 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.navbar-link {
    padding: 0.5rem 1rem;
    color: #374151;
    border-radius: 0.25rem;
    text-decoration: none;
}

.navbar-link:hover {
    background-color: #f3f4f6;
}

.navbar-link.active {
    background-color: #1d4ed8;
    color: white;
}

.dropdown-toggle {
    border: gray solid 1px;
    display: flex;
    align-items: center;
}
.dropdown-toggle:hover {
cursor: pointer;
}

.dropdown-icon {
    margin-left: 0.5rem;
}



.dropdown-item {
    padding: 0.5rem 1rem;
    color: #374151;
    text-decoration: none;
}

 
.dropdown-menu {
    position: absolute;
    gap: 20px;
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 0.5rem;
    /* width: 200px; */
  }
  
  .dropdown-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-group label {
    font-size: 14px;
    color: #333;
  }
  
  select {
    font-size: 14px;
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  



@media (min-width: 768px) {
    .navbar-menu {
        display: flex;
        flex-direction: row;
    }

    .navbar-links {
        flex-direction: row;
        gap: 2rem;
    }

    .menu-button {
        display: none;
    }
}