/* Card container styling */
.card {
    max-width: 300px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    font-family: Arial, sans-serif;
    margin-top: 20px;
  }
  
  /* Card header styling */
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .card-id {
    color: #555;
    font-size: 14px;
  }
  
  .avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  
  /* Card title styling */
  .card-title {
    font-size: 15px;
    font-weight: 600;
    color: #222;
    margin-bottom: 20px;
  }
  
  /* Status indicator section */
  .status-indicator-wrapper {
    display: flex;
    align-items: center;
  }
  
  .status-icon {

    padding: 4px;
    border: #bab9b9 solid 0.5px;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .icon {
    background-color: #666;
    color: white;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  
  /* Status indicator styling */
  .status-indicator {
    display: flex;
    align-items: center;
    border: #bab9b9 solid 0.5px;
    border-radius: 5px;
    padding: 4px 10px;
  }
  
  .dot {
    width: 20px;
    height: 20px;
    background-color: #c4c4c4;
    border-radius: 50%;
    margin-right: 6px;
  }
  
  .status-text {
    color: #666;
    font-size: 14px;
  }
  