.todo-item {
    display: flex;
    align-items: center;
    /* background-color: #f0f0f0; */
    padding: 8px 12px;


}

.todo-text {
    flex-grow: 1;
    margin-left: 8px;
}

.todo-add,
.todo-more {
    background: none;
    border: none;
    /* font-size: 16px; */
    cursor: pointer;
}

.todo-button>img {
    width: 15px;
    height: 15px;
}

.todo-add>img {
    margin-right: 10px;
}