.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns */
    gap: 10px;
    padding: 10px;
}
.grid-container-child {
    /* display: grid; */
    grid-template-columns: repeat(1, 1fr); /* Adjust the number of columns */
    /* gap: 10px; */
    /* padding: 10px; */
}

